import React from 'react';

import Image from '../../utils/OptimizedImage';
import {
  AuthorCardStyle,
  Avatar,
  Info,
  LetterAvatar,
  Name,
  Role,
} from './AuthorCard.styled';

const getRandBgColor = () => {
  const r = Math.round(Math.random() * 128);
  const g = Math.round(Math.random() * 128);
  const b = Math.round(Math.random() * 128);
  return `rgb(${r}, ${g}, ${b})`;
};

const AuthorCard = ({ headshot, first, last, authorRole, size = 'md' }) => (
  <AuthorCardStyle size={size}>
    <figure>
      {headshot ? (
        <Avatar size={size}>
          <Image
            image={headshot?.image?.gatsbyImageData}
            mimage={headshot?.imageMobile?.gatsbyImageData}
            src={headshot?.image?.url}
            msrc={headshot?.imageMobile?.url}
            alt={`Artistic Interpretation (Avatar) of ${first} ${last}`}
          />
        </Avatar>
      ) : (
        <LetterAvatar size={size} style={{ background: getRandBgColor() }}>
          {first && first[0]}
          {last && last[0]}
        </LetterAvatar>
      )}
    </figure>
    <Info size={size}>
      {first && <Name size={size}>{`${first} ${last}`}</Name>}
      {authorRole && <Role size={size}>{authorRole}</Role>}
    </Info>
  </AuthorCardStyle>
);

export default AuthorCard;
